import React, { useEffect, useState } from "react";
import axiosConfig from '../../axiosConfig';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [categoryheading, setCategoryheading] = useState("");

  useEffect(() => {
    axiosConfig.get('/job-category')
      .then(response => {
        if (response.data.success) {
          setCategories(response.data.data);
        } else {
          console.error('Failed to fetch categories');
        }
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);
  useEffect(() => {
    axiosConfig.get('/category-heading')
      .then(response => {
        if (response.data.success) {
          setCategoryheading(response.data.data.title);
        } else {
          console.error('Failed to fetch category heading');
        }
      })
      .catch(error => {
        console.error('Error fetching category heading:', error);
      });
  }, []);
  return (
    <>
      {/* Categories Area */}
      <div className="pt-11 pt-lg-26 pb-lg-16">
        <div className="container">
          {/* Section Top */}
          <div className="row align-items-center pb-14">
            {/* Section Title */}
            <div className="col-12 col-lg-6">
              <div className="text-center text-lg-left mb-13 mb-lg-0">
                <h2 className="font-size-9 font-weight-bold">
                {categoryheading}
                </h2>
              </div>
            </div>
            {/* Section Button */}
            <div className="col-12 col-lg-6">
              <div className="text-center text-lg-right">
                <button className="btn btn-outline-green text-uppercase">
                  Explore All
                </button>
              </div>
            </div>
            {/* Section Button End */}
          </div>
          {/* End Section Top */}
          <div className="row justify-content-center">
            {/* Map over the categories array to render each category dynamically */}
            {categories.map((category, index) => (
              <div key={index} className="col-12 col-xl-3 col-lg-4 col-sm-6 col-xs-8">
                <div className="bg-white border border-color-2 rounded-4 pl-9 pt-10 pb-3 pr-7 hover-shadow-1 mb-9 d-block w-100">
                  <div className="text-blue bg-blue-opacity-1 square-70 rounded-4 mb-7 font-size-7">
                    {/* Render the category icon dynamically */}
                      <img src={'https://devadmin.stackle.in/images/' + category.image} alt={category.title} style={{ width: '50px' }} />

                  </div>
                  {/* Category Content */}
                  <div className="">
                    {/* Render the category title dynamically */}
                    <h5 className="font-size-5 font-weight-semibold text-black-2 line-height-1">
                      {category.title}
                    </h5>
                    {/* Render the category vacancy count dynamically */}
                   
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
